import { SocialIcon } from "react-social-icons";
import "./index.css";

function App() {
  return (
    <>
      <div className="cover">
        <div className="cover-inner">
          <header className="inner">
            <div className="twisted">
              <div className="border-line"></div>
              <h1 className="headline">coming soon</h1>
              <div className="border-line"></div>
            </div>
            <div className="inner"></div>
            <p className="p1">MOJACKO</p>
            <p className="p2"> {"<MKO />"}</p>
            <div>
              <SocialIcon
                url="https://linkedin.com/in/kourdimarouan"
                target="_blank"
              />
              <SocialIcon
                url="https://facebook.com/kourdimarwan"
                target="_blank"
              />
              <SocialIcon
                url="https://www.instagram.com/marwankourdi/"
                target="_blank"
              />
            </div>
          </header>
          <div className="c">
            <p className="co">
              contact: <a href="mailto:contact@kourdi.me">contact@kourdi.me</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
